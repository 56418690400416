<template>
  <validation-observer ref="addUserForm" #default="{ invalid }">
    <b-form @submit.prevent="addUser">
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group label="First Name">
            <validation-provider #default="{ errors }" name="First Name" vid="FirstName" rules="required">
              <b-form-input id="FirstName" v-model="firstName" :state="errors.length > 0 ? false : null" name="Last Name"
                placeholder="First Name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="12">
          <b-form-group label="Last Name">
            <validation-provider #default="{ errors }" name="Last Name" vid="LastName" rules="required">
              <b-form-input id="LastName" v-model="lastName" :state="errors.length > 0 ? false : null" name="Last Name"
                placeholder="Last Name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Email" label-for="login-email">
            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
              <b-form-input id="login-email" v-model="email" :state="errors.length > 0 ? false : null" name="login-email"
                placeholder="john@example.com" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- password -->
        <b-col cols="12">
          <b-form-group label="Username">
            <validation-provider #default="{ errors }" name="Username" vid="Username" rules="required">
              <b-form-input id="Username" v-model="userName" :state="errors.length > 0 ? false : null" name="Username"
                placeholder="Username" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Phone Number">
            <validation-provider #default="{ errors }" rules="required|integer" name="Number">
              <b-form-input v-model="phoneNumber" :state="errors.length > 0 ? false : null"
                placeholder="Enter Number Only" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Password">
            <validation-provider rules="required|password" v-slot="{ errors }" name="password">
              <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
              <b-form-input v-model="password" :type="showPassword ? 'text' : 'password'"
                  :state="errors[0] ? false : null" placeholder="Password" />
                  <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" @click="showPassword = !showPassword"
                    :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'" />

                </b-input-group-append>
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
          </b-form-group>

        </b-col>

        
        <b-col cols="12">
          <b-form-group label="Repeat Password">
            <validation-provider rules="required|confirmed:password" v-slot="{ errors }" name="Confirm Password">
              <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                <b-form-input v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                  :state="errors[0] ? false : null" placeholder="Repeat Password"  />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" @click="showConfirmPassword = !showConfirmPassword"
                    :icon="showConfirmPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>


        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
            :disabled="invalid">
            <b-spinner small v-if="loading" />
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BForm,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      password: "",
      confirmPassword: "",
      password: "",

      showPassword: false,         // for password field
      showConfirmPassword: false,  // for confirm password field
      phoneNumber: "",
      // validation rules
      required,
      email,

      showSpinner: false,
    };
  },
  props: {
    token: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("userModule", {
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("userModule", ["addUserAction"]),
    success() {
      this.$swal({
        title: "Success",
        text:
          "User Registration Successful, Please check your email for the email confirmation link",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    addUser() {
      this.$refs.addUserForm.validate().then((success) => {
        if (success) {
          let payload = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            userName: this.userName,
            password: this.password,
            confirmPassword: this.confirmPassword,
            phoneNumber: this.phoneNumber,
          };
          this.addUserAction(payload)
            .then(() => {
              this.success();
              this.$emit("reload", true);
              this.$bvModal.hide("modal-register");
            })
            .catch((error) => {
              this.error(error.response.data);
              this.$emit("reload", true);
              this.$bvModal.hide("modal-register");
            });
        }
      });
    },
  },
};
</script>
