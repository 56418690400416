<template>
  <b-row>
    <b-modal id="modal-register" ok-only ok-variant="secondary" ok-title="Cancel" centered size="lg" title="Add User"
      hide-footer>
      <AddUser :token="token" @reload="data" />
    </b-modal>

    <b-col cols="12">
      <div class="d-flex justify-content-end">
        <b-button variant="success" v-b-modal.modal-register>
          <i class="feather icon-user"></i>
          Add User
        </b-button>
      </div>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="users" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template v-slot:cell(Action)="data">
          <b-button variant="primary" size="sm" class="mr-1" @click="getIdOfUser(data.item)">
            <i class="feather icon-edit"></i>

          </b-button>
        </template>
        <template v-slot:cell(isActive)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox v-model="data.item.isActive" @change="updateUser(data.item.id, data.item.isActive)">
            </b-form-checkbox>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import AddUser from "./AddUser.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    AddUser,
  },
  data() {
    return {
      showSpinner: true,
      perPage: 5,

      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      token: localStorage.getItem("accessToken"),
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "userName",
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        "email",
        "isActive",
        "phoneNumber",
        "Action",
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("userModule", {
      users: "users",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getUsers();
  },
  async mounted() {
    await this.getUserListAction()
      .then(() => {
        this.totalRows = this.users.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
  methods: {
    ...mapActions("userModule", ["getUserListAction"]),
    data(data) {
      if (data) {
        this.getUserListAction();
      }
    },
    updateUser(id, value) {
      let tenant = localStorage.getItem("userTenant");
      let payload = {
        activateUser: value,
        userId: id,
      };


      axios.defaults.headers.Authorization = "Bearer " + this.token;
      //
      const url = process.env.VUE_APP_API_URL
      axios
        .post(
          url + "users/" + id + "/toggle-status", payload

        )
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Active status changed!",

            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getUserListAction();
        })
        .catch((error) => {
          this.$swal({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          this.getUserListAction();
        });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getIdOfUser(item) {
      this.$router.push({ name: "role-view-user", params: { id: item.id } });
    },
  },
};
</script>
